import { EventEmitter, Injectable, NgZone } from '@angular/core';
import { environment } from '@nowffc-environment/environment';
import { ScriptLoaderService } from '@nowffc-shared/services/script-loader/script-loader.service';
import { BillwerkOrder } from '@nowffc-shared/interfaces/billwerk-order';
import { BillwerkJSException } from '@nowffc-shared/exceptions/billwerkjs.exception';
import { WindowRef } from '@nowffc-shared/services/window/window';
import { OrderPreview } from '@nowffc-shared/interfaces/order-preview';
import { PaymentChangeEventData } from '@nowffc-shared/interfaces/payment-change-event-data';
import { PaymentBearer } from '@nowffc-shared/types/payment-bearer';
import { BillwerkPaymentChange } from '@nowffc-shared/interfaces/billwerk-payment-change';
import { BillwerkServiceInterface } from '@nowffc-shared/interfaces/billwerk-service-interface';
import { BillwerkErrorDetails } from '@nowffc-shared/interfaces/billwerk-error-details';
import { PaymentOrderFinalizeData } from '@nowffc-shared/interfaces/payment-order-finalize-data';
import { BillwerkFinalizeSuccess } from '@nowffc-shared/interfaces/billwerk-finalize-success';
import { BillwerkCallbackErrorInterface } from '@nowffc-shared/interfaces/billwerk-callback-error-interface';
import { PaymentChangeFinalizeData } from '@nowffc-shared/interfaces/payment-change-finalize-data';
import { ErrorsService } from '@nowffc-errors/services/errors.service';

/**
 * Subscription service for currentSubscription information
 */
@Injectable({ providedIn: 'root' })
export class BillwerkService implements BillwerkServiceInterface {
  /**
   * Billwerk Order Object
   */
  // @ts-ignore
  order: BillwerkOrder;

  /**
   * Portal Service
   *
   * see: https://developer.billwerk.io/Docs/subscriptionJS_Reference#subscriptionJS-portal
   * see: https://developer.billwerk.io/Docs/subscriptionJS_Introduction#portal
   */
  portal!: {
    upgradePayInteractive: (
      paymentBearer: any,
      paymentInfo: any,
      order: object,
      success: (bwOrder: BillwerkOrder) => void,
      error: object,
    ) => void;
    paymentChange: (
      paymentService: object,
      paymentInfo: any,
      success: (data: BillwerkPaymentChange) => void,
      error: object,
    ) => void;
  };

  /**
   * Payment Service
   *
   * see: https://developer.billwerk.io/Docs/subscriptionJS_Reference#subscriptionJS-payment
   * see: https://developer.billwerk.io/Docs/subscriptionJS_Introduction
   */
  // @ts-ignore
  payment: (
    paymentService: object,
    paymentInfo: any,
    success: boolean,
    error: object,
  ) => void;

  /**
   * Sepa Mandat Text will be load async oer Billwerk Serf Service Settings
   */
  sepaMandateText = new EventEmitter();

  /**
   * Callback for setPaymentData
   */
  callbackSetPaymentData = new EventEmitter<BillwerkOrder>();

  /**
   * Callback for setPaymentData failure
   */
  callbackSetPaymentDataFailure = new EventEmitter<BillwerkJSException>();

  /**
   * Callback for changePaymentData
   */
  callbackChangePaymentData = new EventEmitter<PaymentChangeEventData>();

  /**
   * Callback for finalize payment change
   */
  callbackFinalizePaymentChange = new EventEmitter<PaymentChangeFinalizeData>();

  /**
   * Callback for finalize payment on submit order
   */
  callbackFinalizePaymentOrder = new EventEmitter<PaymentOrderFinalizeData>();

  /**
   * Wire DI
   */
  constructor(
    private readonly scriptLoaderService: ScriptLoaderService,
    private readonly errorsService: ErrorsService,
    private readonly windowRef: WindowRef,
    private readonly zone: NgZone,
  ) {
    this.resetService();
  }

  /**
   * Reset Order Object
   */
  resetOrder() {
    this.order = {
      // @ts-ignore
      OrderId: undefined as string,
      // @ts-ignore
      GrossTotal: undefined as number,
      // @ts-ignore
      Currency: undefined as string,
    };
  }

  /**
   * Reset Billwerk Services an Attributes
   */
  resetService() {
    // Reset Services and Variables
    // @ts-ignore
    this.payment = undefined;
    // @ts-ignore
    this.portal = undefined;
    this.resetOrder();
  }

  /**
   * init subscriptionJS for Billwerk
   */
  async init(providerReturnUrl = ''): Promise<boolean> {
    this.resetService();
    const scriptLoaded = await this.scriptLoaderService
      .loadScript('billwerk')
      .then((success) => {
        return success;
      });

    if (scriptLoaded) {
      // Init SubscriptionJS
      this.payment = new this.windowRef.nativeWindow.SubscriptionJS.Payment(
        {
          publicApiKey: environment.billwerk.publicApiKey,
          providerReturnUrl,
        },
        () => {
          return true;
        },
        () => {
          // @ts-ignore
          this.payment = undefined;
          throw new BillwerkJSException(
            'SubscriptionJS Payment initialization failed!',
            'billwerk.error.subscriptionjs.payment.init.failed',
            'billwerk.error.subscriptionjs.payment.init.failed.message',
            true,
          );
        },
      );

      // Get SEPA Mandate Text
      this.windowRef.nativeWindow.SubscriptionJS.selfServiceSettings(
        environment.billwerk.publicApiKey,
        environment.billwerk.locale,
        (selfServiceSettings: any) => {
          this.sepaMandateText.emit(
            selfServiceSettings.SEPADDMandate.MandateTemplateText,
          );
        },
        () => {
          throw new BillwerkJSException(
            'SubscriptionJS selfServiceSettings initialization failed, no sepa text',
            'billwerk.error.subscriptionjs.payment.init.failed',
            'billwerk.error.subscriptionjs.payment.init.failed.message',
            true,
          );
        },
      );
    }

    return scriptLoaded;
  }

  /**
   * set billwerk order
   */
  setOrder(order: OrderPreview): void {
    this.order.OrderId = order.id;
    this.order.Currency = order.currency;
    this.order.GrossTotal = order.total;
  }

  /**
   * get billwerk order
   */
  getOrder(): BillwerkOrder {
    return this.order;
  }

  /**
   * Billwerk Error Details
   */
  getErrorLogDetails() {
    return {
      paymentService: this.payment,
      portalService: this.portal,
      order: this.order,
    };
  }

  /**
   * Init Portal Service
   */
  initPortalService(customerToken: string): void {
    if (customerToken) {
      this.portal = new this.windowRef.nativeWindow.SubscriptionJS.Portal(
        customerToken,
      );
    } else {
      // @ts-ignore
      this.portal = undefined;
    }
  }

  /**
   * changePaymentData / paymentChange
   */
  async changePaymentData(paymentInfo: object, paymentBearer: PaymentBearer) {
    const paymentService = paymentBearer === 'CreditCard' ? null : this.payment;

    this.checkPaymentData(paymentInfo, paymentBearer, false);

    try {
      this.portal.paymentChange(
        // @ts-ignore
        paymentService,
        paymentInfo,
        (data: BillwerkPaymentChange) => {
          if (
            data.Url &&
            (paymentBearer === 'PayPal' ||
              paymentBearer === 'CreditCard' ||
              paymentBearer === 'AmazonPay')
          ) {
            this.windowRef.nativeWindow.location.href = data.Url;
            return;
          } else {
            // Handle Data
            let returnData: PaymentChangeEventData;
            if (data.ContractId && data.CustomerId) {
              returnData = {
                success: true,
                error: undefined,
                value: true,
              };
            } else {
              returnData = {
                success: false,
                error:
                  'billwerk.error.subscriptionjs.portal.paymentChange.failed.message',
                value: false,
              };
            }
            this.zone.run(() => {
              this.callbackChangePaymentData.emit(returnData);
            });
            return;
          }
        },
        (error: object) => {
          console.error(error);
          const returnData: PaymentChangeEventData = {
            success: false,
            error:
              'billwerk.error.subscriptionjs.portal.paymentChange.failed.message',
            value: false,
          };
          this.zone.run(() => {
            this.callbackChangePaymentData.emit(returnData);
          });
        },
      );
    } catch (e) /* istanbul ignore next */ {
      console.error(e);
      throw new BillwerkJSException(
        'SubscriptionJS Portal changePaymentData is failed. Unknown',
        'billwerk.error.subscriptionjs.portal.general.failed',
        'billwerk.error.subscriptionjs.portal.general.failed.message',
      );
    }
  }

  /**
   * Will be trigger on Payment Change
   * Trigger for PayPal, Amazon Pay or CC 3ds2
   *
   * For interactive PSPs like PayPal this needs to be executed on the
   * return page the PSP redirects to after finishing a payment.
   * It triggers finalizing the order in billwerk. The success and error
   * callback can be used to inform the customer if the order succeeded ot not.
   */
  finalizePaymentChange() {
    this.windowRef.nativeWindow.SubscriptionJS.finalize(
      () => {
        this.zone.run(() => {
          this.callbackFinalizePaymentChange.emit({
            success: true,
            error: undefined,
            value: undefined,
          });
        });
      },
      (error: object) => {
        this.zone.run(() => {
          this.callbackFinalizePaymentChange.emit({
            success: false,
            error:
              'billwerk.error.subscriptionjs.portal.paymentFinalize.failed.message',
            value: this.getBillwerkErrorCallbackDetails(error),
          });
        });
      },
    );
  }

  /**
   * Will be triggered in Submit Order
   * Trigger for PayPal, Amazon Pay or CC 3ds2
   *
   * For interactive PSPs like PayPal this needs to be executed on the
   * return page the PSP redirects to after finishing a payment.
   * It triggers finalizing the order in billwerk. The success and error
   * callback can be used to inform the customer if the order succeeded ot not.
   */
  finalizePaymentOrder() {
    const startDate = new Date();

    /* istanbul ignore next */
    this.windowRef.nativeWindow.SubscriptionJS.finalize(
      (bwOrder: BillwerkFinalizeSuccess) => {
        // We are trying to debug an production issue with two parallel orders
        // To help debug, we want to log slow finalize calls
        const finalizingTimeInSeconds =
          (new Date().getTime() - startDate.getTime()) / 1000;
        const shouldLogSlowFinalize = finalizingTimeInSeconds > 120;

        /* istanbul ignore if */
        if (shouldLogSlowFinalize) {
          this.errorsService.logBugsnag(
            'Finalize for payment order took very long',
            {
              addInfos: {
                durationInSeconds: finalizingTimeInSeconds,
                bwOrder,
              },
              severity: 'info',
            },
          );
        }

        const success = bwOrder.OrderStatus !== 'Declined' && !!bwOrder.OrderId;
        const returnValue: PaymentOrderFinalizeData = {
          success,
          error: undefined,
          value: bwOrder,
        };

        if (!success) {
          returnValue.error = this.getBillwerkErrorCallbackDetails();
          returnValue.error.errorKey =
            'billwerk.error.subscriptionjs.portal.paymentFinalizeOrder.order.declined';
          returnValue.error.forwardUrl = '/buchen/fehler';
        }

        this.zone.run(() => {
          this.callbackFinalizePaymentOrder.emit(returnValue);
        });
      },
      (errorCallback: any) => {
        const returnValue: PaymentOrderFinalizeData = {
          success: false,
        };

        returnValue.error = this.getBillwerkErrorCallbackDetails(errorCallback);
        returnValue.error.errorKey =
          'billwerk.error.subscriptionjs.portal.paymentFinalizeOrder.failed.message';

        // todo sammeln von fehlerfällen in bugsnag um später genauere Infos an den kunden zu liefern
        if (
          errorCallback &&
          errorCallback.errorCode &&
          errorCallback.errorCode.indexOf('Canceled') > -1
        ) {
          // User hat beim Provider den Vorgang abgebrochen
          returnValue.error.errorKey =
            'billwerk.error.subscriptionjs.portal.paymentFinalize.cancelled.by.user';
          returnValue.error.forwardUrl = '/buchen/bezahlen';
        } else {
          returnValue.error.forwardUrl = '/buchen/fehler';
        }
        this.zone.run(() => {
          this.callbackFinalizePaymentOrder.emit(returnValue);
        });
      },
    );
  }

  /**
   * setPaymentData / upgradePayInteractive
   */
  async setPaymentData(
    paymentInfo: any,
    paymentBearer: PaymentBearer,
  ): Promise<void> {
    this.checkPaymentData(paymentInfo, paymentBearer, true);

    try {
      this.portal.upgradePayInteractive(
        paymentBearer === 'CreditCard' ? null : this.payment,
        paymentInfo,
        this.order,
        (bwOrder: BillwerkOrder) => {
          if (
            bwOrder.Url &&
            (paymentBearer === 'PayPal' ||
              paymentBearer === 'CreditCard' ||
              paymentBearer === 'AmazonPay')
          ) {
            this.windowRef.nativeWindow.location.href = bwOrder.Url;
            return;
          } else {
            this.zone.run(() => {
              this.callbackSetPaymentData.emit(bwOrder);
            });
            return;
          }
        },
        () => {
          this.zone.run(() => {
            this.callbackSetPaymentDataFailure.emit(
              new BillwerkJSException(
                'SubscriptionJS Portal upgradePayInteractive is failed',
                'billwerk.error.subscriptionjs.portal.upgradePayInteractive.failed',
                'billwerk.error.subscriptionjs.portal.upgradePayInteractive.failed.message',
              ),
            );
          });
        },
      );
    } catch (e) {
      console.error(e);
      throw new BillwerkJSException(
        'SubscriptionJS Portal upgradePayInteractive is failed. No Order ID came back',
        'billwerk.error.subscriptionjs.portal.general.failed',
        'billwerk.error.subscriptionjs.portal.general.failed.message',
      );
    }
  }

  /**
   * Check is all Payment Data is available
   */
  checkPaymentData(
    paymentInfo: any,
    paymentBearer: PaymentBearer,
    checkOrder: boolean,
  ) {
    // Check Portal Service
    if (typeof this.portal === 'undefined') {
      throw new BillwerkJSException(
        'SubscriptionJS Portal not initialization yet in upgradePayInteractive',
        'billwerk.error.subscriptionjs.portal.init.failed',
        'billwerk.error.subscriptionjs.portal.init.failed.message',
      );
    }

    // Check Billwerk Order
    if (
      checkOrder &&
      (typeof this.order.Currency === 'undefined' ||
        typeof this.order.OrderId === 'undefined' ||
        typeof this.order.GrossTotal === 'undefined')
    ) {
      throw new BillwerkJSException(
        'Billwerk Order Inconsistent in upgradePayInteractive',
        'billwerk.error.subscriptionjs.order.inconsistent',
        'billwerk.error.subscriptionjs.order.inconsistent.message',
      );
    }

    // Check Payment Service
    if (!this.payment) {
      throw new BillwerkJSException(
        'SubscriptionJS Payment not initialization yet in upgradePayInteractive',
        'billwerk.error.subscriptionjs.payment.init.failed',
        'billwerk.error.subscriptionjs.payment.init.failed.message',
      );
    }

    // Check paymentInfo
    if (!paymentInfo) {
      throw new BillwerkJSException(
        'SubscriptionJS PaymentInfo Data not set in upgradePayInteractive',
        'billwerk.error.subscriptionjs.paymentinfo.not.set',
        'billwerk.error.subscriptionjs.paymentinfo.not.set.message',
      );
    }

    // Check paymentBearer for Bank Account / Debit
    /* istanbul ignore if */
    if (
      paymentBearer === 'BankAccount' &&
      (paymentInfo.bearer !== environment.billwerk.paymentMethods.debitCard ||
        paymentInfo.accountHolder.length === 0 ||
        paymentInfo.iban.length === 0 ||
        paymentInfo.bic.length === 0 ||
        paymentInfo.mandateText.length === 0)
    ) {
      throw new BillwerkJSException(
        'SubscriptionJS Payment not initialization yet!',
        'billwerk.error.subscriptionjs.payment.init.failed',
        'billwerk.error.subscriptionjs.payment.init.failed.message',
      );
    }
  }

  /**
   *  Billwerk Error Callback Details
   */
  private getBillwerkErrorCallbackDetails(
    data: BillwerkCallbackErrorInterface = {},
  ): BillwerkErrorDetails {
    /* istanbul ignore next */
    return {
      errorKey: 'billwerk.error.subscriptionjs.general',
      forwardUrl: undefined,
      billwerkErrorCode: data.errorCode ? data.errorCode : 'not defined',
      billwerkDetails: data.details ? data.details : 'not defined',
      billwerkMessage: data.errorMessage ? data.errorMessage : 'not defined',
      billwerkReferenceId: data.referenceId ? data.referenceId : 'not defined',
      billwerkReferenceUrl: data.referenceUrl
        ? data.referenceUrl
        : 'not defined',
      billwerk: this.getErrorLogDetails(),
    };
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '@nowffc-environment/environment';
import { Order } from '@nowffc-shared/interfaces/order';
import { OrderPreview } from '@nowffc-shared/interfaces/order-preview';
import { Product } from '@nowffc-shared/interfaces/product';
import { addDays, parseISO } from 'date-fns';
import { Subscription } from '@nowffc-shared/interfaces/subscription';
import { Address } from '@nowffc-shared/interfaces/adress';
import { PaymentMethodData } from '@nowffc-shared/interfaces/payment-method-data';
import { PreviewSubscriptionError } from '@nowffc-shared/types/preview-subscription-error';
import { GetSelfServiceTokenError } from '@nowffc-shared/types/get-self-service-token-error';
import { firstValueFrom, Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { SubscriptionResponse } from '@nowffc-shared/interfaces/response/subscription-response';
import { ChangeSubscriptionError } from '@nowffc-shared/types/change-subscription-error';
import { OrderPreviewResponse } from '@nowffc-shared/interfaces/response/order-preview-response';
import { LocalStorageService } from '@nowffc-shared/services/storage/local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { VerifySubscriptionError } from '@nowffc-shared/types/verify-subscription-error';
import { DeletePaymentMethodError } from '@nowffc-shared/types/delete-payment-method-error';
import { MaybeValue } from '@nowffc-shared/types/maybe-value';
import { Invoice } from '@nowffc-shared/interfaces/invoice';
import { InvoiceDownloadUrl } from '@nowffc-shared/interfaces/invoice-download-url';
import { CancelSubscriptionError } from '@nowffc-shared/types/cancel-subscription-error';
import { CustomerBalance } from '@nowffc-shared/interfaces/customer-balance';
import { CancellationType } from '@nowffc-shared/types/cancellation-type';
import { CancelSubscriptionWithoutLoginResponse } from '@nowffc-shared/interfaces/cancel-subscription-without-login-response';
import { CustomerSubscriptionState } from '@nowffc-shared/enums/customer-subscription-state.enum';
import * as fromStore from '@nowffc-state/store';
import { Store } from '@ngrx/store';
import { GenericError } from '@nowffc-shared/types/generic-error';
import { PreValidatePaymentDataChangeResponse } from '@nowffc-shared/interfaces/response/pre-validate-payment-data-change-response';
import { CancellationPreview } from '@nowffc-shared/interfaces/cancellation-preview';
import { CancelSubscription } from '@nowffc-shared/interfaces/cancel-subscription';
import { SubscriberData } from '@nowffc-shared/interfaces/subscriber-data/subscriber-data';
import { STORAGE_KEY_PRESELECTED_PRODUCT } from '@nowffc-shared/services/product-selection/product-selection.service';

interface OrderContext {
  order: OrderPreview;
  product: Product;
  offeringJwt?: string;
  promotionCode?: string;
}

/**
 * Subscription service for currentSubscription information
 */
@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  /**
   * Order storage key
   */
  readonly STORAGE_KEY_ORDER = 'ffc-order';

  /**
   * helper for current subscription property
   */
  currentSubscriptionSubject = new ReplaySubject<Subscription>();

  /**
   * Observable that holds the current subscription
   */
  public currentSubscription$: Observable<Subscription> =
    this.currentSubscriptionSubject.asObservable();

  /**
   * Wire DI
   */
  constructor(
    private readonly http: HttpClient,
    private readonly storageService: LocalStorageService,
    private readonly translateService: TranslateService,
    private readonly store: Store,
  ) {}

  /**
   * Determines a customer's subscription state
   * */
  async determineCustomerSubscriptionState(): Promise<CustomerSubscriptionState> {
    const subscription = await firstValueFrom(this.currentSubscription$);

    const userIsInDeletionQueue = await firstValueFrom(
      this.store.select(fromStore.auth.selectIsUserOnDeletionQueue),
    );

    if (subscription!.isPartnerContractConflict) {
      return CustomerSubscriptionState.PARTNER_CONFLICT;
    } else if (subscription!.isPartnerPurchase) {
      return CustomerSubscriptionState.PARTNER;
    } else if (subscription!.isInAppPurchase) {
      return CustomerSubscriptionState.IAP;
    } else if (subscription!.isFree) {
      return CustomerSubscriptionState.FFC_FREE;
    } else if (userIsInDeletionQueue) {
      return CustomerSubscriptionState.FFC_IN_DELETION;
    } else if (subscription!.userCanRevertCancellation) {
      return CustomerSubscriptionState.FFC_IN_CANCELLATION;
    } else if (subscription!.userIsInTrialPhase) {
      return CustomerSubscriptionState.FFC_TRIAL;
    }

    return CustomerSubscriptionState.FFC_PAY;
  }

  /**
   * Change the abo
   *
   * Upgrade/Downgrade the current "Paket".
   */
  async changeSubscription(
    product: Product,
    address: Address,
    payment: PaymentMethodData,
    firstName: string,
    lastName: string,
    withdrawalClauseAcceptedAt?: Date,
    discountId?: string,
    originPlatform?: string,
    offeringJwt?: string,
    promotionCode?: string,
  ): Promise<MaybeValue<Order, ChangeSubscriptionError>> {
    return this.http
      .put<Order>(`${environment.bffUrl}/subscription`, {
        productId: product.id,
        address,
        cardPan: payment.cardpan,
        iban: payment.iban,
        bic: payment.bic,
        paymentBearerType: payment.paymentBearerType,
        firstName,
        lastName,
        withdrawalClauseAcceptedAt: withdrawalClauseAcceptedAt
          ? withdrawalClauseAcceptedAt.toISOString()
          : null,
        discountId: discountId ? discountId : null,
        platform: originPlatform ? originPlatform : null,
        promotionCode: promotionCode ?? null,
        offeringJwt,
      })
      .toPromise()
      .then(
        (res) => {
          return {
            success: true,
            value: res!,
          };
        },
        (err: HttpErrorResponse) => {
          return {
            success: false,
            error: err.error.title,
          };
        },
      );
  }

  /**
   * cancel the subscription of the user
   */
  async cancelSubscription(
    reasonId: string,
    reasonText: string,
  ): Promise<MaybeValue<CancelSubscription, CancelSubscriptionError>> {
    return firstValueFrom(
      this.http.post<CancelSubscription>(
        `${environment.bffUrl}/cancelSubscription`,
        {
          reasonId,
          reasonText,
        },
      ),
    ).then(
      (res) => {
        return {
          success: true,
          value: res!,
        };
      },
      (err: HttpErrorResponse) => {
        return {
          success: false,
          error: err.error.title,
        };
      },
    );
  }

  /**
   * preview the cancellation of a users subscription
   */
  async previewCancelSubscription(): Promise<
    MaybeValue<CancellationPreview, GenericError>
  > {
    return firstValueFrom(
      this.http.get<CancellationPreview>(
        `${environment.bffUrl}/cancelSubscription/preview`,
      ),
    ).then(
      (res) => {
        return {
          success: true,
          value: res!,
        };
      },
      (httpError: HttpErrorResponse) => {
        return {
          success: false,
          error: httpError.error.title,
        };
      },
    );
  }

  /**
   * request cancellation of the subscription of a user without login
   */
  async cancelSubscriptionWithoutLogin(
    accountId: string,
    emailAddress: string,
    birthDate: string,
    cancellationType: CancellationType,
    cancellationReason?: string,
    desiredCancellationDate?: Date,
  ): Promise<MaybeValue<CancelSubscriptionWithoutLoginResponse, GenericError>> {
    cancellationReason =
      cancellationType === 'EXTRAORDINARY' ? cancellationReason : undefined;
    desiredCancellationDate =
      cancellationType === 'EXTRAORDINARY'
        ? desiredCancellationDate
        : undefined;

    return firstValueFrom(
      this.http.post<CancelSubscriptionWithoutLoginResponse>(
        `${environment.bffUrl}/cancelSubscriptionWithoutLogin`,
        {
          customerDetails: {
            accountId,
            emailAddress,
            birthDate,
          },
          cancellationType,
          cancellationReason,
          desiredCancellationDate,
        },
      ),
    ).then(
      (res) => {
        return {
          success: true,
          value: res!,
        };
      },
      (err: HttpErrorResponse) => {
        return {
          success: false,
          error: err.error.title,
        };
      },
    );
  }

  async cancelSubscriptionResponseDownload(): Promise<void> {
    return firstValueFrom(
      this.http.get(
        `${environment.bffUrl}/cancelSubscriptionWithoutLogin/download`,
      ),
    ).then(() => {});
  }

  async objectToMigration(): Promise<MaybeValue<boolean, GenericError>> {
    return firstValueFrom(
      this.http.post(`${environment.bffUrl}/productMigration/object`, null),
    ).then(
      () => {
        return {
          success: true,
          value: true,
        };
      },
      (httpError: HttpErrorResponse) => {
        return {
          success: false,
          error: httpError.error.title,
        };
      },
    );
  }

  /**
   * withdraw from a cancellation
   */
  async withdrawCancellation(): Promise<
    { success: true } | { success: false; error: GenericError }
  > {
    return this.http
      .post(`${environment.bffUrl}/withdrawCancellation`, null)
      .toPromise()
      .then(
        () => {
          return { success: true, value: undefined };
        },
        (err: HttpErrorResponse) => {
          return {
            success: false,
            error: err.error.title,
          };
        },
      );
  }

  /**
   * get the current subscription of the user
   */
  async getSubscription(): Promise<Subscription | undefined> {
    return this.http
      .get<SubscriptionResponse>(`${environment.bffUrl}/subscription`)
      .toPromise()
      .then(
        (res) => {
          if (res) {
            const subscriptionResponse: Subscription = {
              customerStatusKey: res.customerStatusKey,
              endDate: res.endDate ? parseISO(res.endDate) : undefined,
              isFree: res.productName.toUpperCase() === 'FREE',
              nextBillingDate: res.nextBillingDate
                ? parseISO(res.nextBillingDate)
                : undefined,
              paymentBearerTypeKey: res.paymentBearerTypeKey,
              productName: res.productName,
              customerProducts: res.productSubscriptions.map(
                (productSubscription) => {
                  return {
                    name: productSubscription.productName,
                    productType: productSubscription.contractType,
                    paymentBearerTypeKey: productSubscription.paymentBearerType,
                    startDate: productSubscription.startDate,
                    endDate: productSubscription.cancellationDate,
                    nextBillingDate: productSubscription.nextBillingDate,
                  };
                },
              ),
              billingPeriod: res.billingPeriod,
              // @ts-ignore
              startDate: res.startDate ? parseISO(res.startDate) : undefined,
              statusKey: res.statusKey,
              userCanCancelSubscription: res.userCanCancelSubscription,
              userCanRedeemPrepaidCard: res.userCanRedeemPrepaidCard,
              userCanRevertCancellation: res.userCanRevertCancellation,
              userCanDeletePaymentMethod: res.userCanDeletePaymentMethod,
              userIsInTrialPhase: res.userIsInTrialPhase,
              userCanInheritPaymentBearer: res.userCanInheritPaymentBearer,
              userCanBeRetained: res!.userCanBeRetained,
              trialEndDate: res.trialEndDate
                ? parseISO(res.trialEndDate)
                : undefined,
              nextPhase: res.nextPhase
                ? {
                    product: res.nextPhase.product,
                    startDate: parseISO(res.nextPhase.startDate),
                  }
                : undefined,
              isInAppPurchase: res.paymentBearerTypeKey
                ? res.paymentBearerTypeKey.search(/(amazoniap|appleiap)/) > 0
                : false,
              isPartnerPurchase: res.paymentBearerTypeKey
                ? res.paymentBearerTypeKey.search(/(partner)/) > 0
                : false,
              partnerName: res.partner,
              isPartnerContractConflict:
                !!res.partnerContractConflictResolution,
              exceptionalCancellationAllowed:
                res.partnerContractConflictResolution ===
                'EXCEPTIONAL_CANCELLATION',
              exceptionalCancellationInProgress:
                res.partnerContractConflictResolution ===
                'CANCELLATION_IN_PROGRESS',
              externalConflictResolution:
                res.partnerContractConflictResolution === 'EXTERNAL_HANDLING',
              userCanTransition: res.userCanTransition,
              hasDiscount: res.hasDiscount,
              nextBillingPreviewTotalGross:
                res.nextBillingPreviewAmount?.totalGross,
              nextBillingPreviewDate: res.nextBillingPreviewAmount?.billingDate
                ? parseISO(res.nextBillingPreviewAmount.billingDate)
                : undefined,
              writtenOff: !!res.writtenOff,
              isNewCustomer: !!res.isNewCustomer,
              eligibleForCreditTypes: res.eligibleForCreditTypes,
            };

            this.currentSubscriptionSubject.next(subscriptionResponse);

            return subscriptionResponse;
          } else {
            return undefined;
          }
        },
        () => {
          return undefined;
        },
      );
  }

  /**
   * get the Subscriber Data of the user
   */
  async getSubscriberData(): Promise<MaybeValue<SubscriberData, GenericError>> {
    return this.http
      .get<SubscriberData>(`${environment.bffUrl}/subscriberData`)
      .pipe(
        map((data) => {
          this.convertIsoDates(data);
          this.mapConflictPlatform(data);
          return data as SubscriberData;
        }),
      )
      .toPromise()
      .then(
        (res) => {
          return {
            success: true,
            value: res!,
          };
        },
        (rej: HttpErrorResponse) => {
          return { success: false, error: rej.error.title };
        },
      );
  }

  private mapConflictPlatform(data: SubscriberData) {
    if (!data.computed.subscriptionConflict) {
      return data;
    }
    const cleanConflictPlatform =
      data.computed.subscriptionConflict.platform.toLowerCase();

    switch (cleanConflictPlatform) {
      case 'dtag':
        data.computed.subscriptionConflict.platform = 'MagentaTV';
        break;
      case 'apple':
        data.computed.subscriptionConflict.platform = 'Apple In-App-Purchase';
        break;
      case 'amazon':
        data.computed.subscriptionConflict.platform = 'Amazon In-App-Purchase';
        break;
    }

    return data;
  }

  private convertIsoDates(body: any) {
    function isIsoDateString(value: any): boolean {
      if (typeof value === 'string') {
        return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?Z$/.test(value);
      }
      return false;
    }

    if (body === null) {
      return body;
    }
    if (typeof body !== 'object') {
      return body;
    }
    for (const key of Object.keys(body)) {
      const value = body[key];
      if (isIsoDateString(value)) {
        body[key] = new Date(value);
      } else if (typeof value === 'object') {
        this.convertIsoDates(value);
      }
    }
  }

  /**
   * get balance of active ffc contract
   */
  async getCustomerBalance(): Promise<
    MaybeValue<CustomerBalance, GenericError>
  > {
    return this.http
      .get<CustomerBalance>(`${environment.bffUrl}/customerBalance`)
      .toPromise()
      .then(
        (res) => {
          return {
            success: true,
            value: res!,
          };
        },
        (rej: HttpErrorResponse) => {
          return { success: false, error: rej.error.title };
        },
      );
  }

  /**
   * verify a subscription in the last step.
   * Accepted or Decline
   *
   * This method will be called after the PSP has successfully stored the Payment Method
   */
  async verifySubscription(orderId: string): Promise<
    | {
        success: false;
        error: VerifySubscriptionError;
      }
    | {
        success: true;
        error: undefined;
      }
  > {
    return this.http
      .post(`${environment.bffUrl}/verifySubscription/${orderId}`, {})
      .toPromise()
      .then(
        () => {
          return { success: true, error: undefined };
        },
        (err: HttpErrorResponse) => {
          return { success: false, error: err.error.title };
        },
      );
  }

  /**
   * Preview the abo
   *
   * Check the new Subscription if the user can checkout the order
   */
  async previewSubscription(params: {
    product: Product;
    discountId?: string;
    offeringJwt?: string;
    promotionCode?: string;
  }): Promise<MaybeValue<OrderPreview, PreviewSubscriptionError>> {
    return this.http
      .post<OrderPreviewResponse>(`${environment.bffUrl}/previewSubscription`, {
        productId: params.product.id,
        discountId: params.discountId ?? null,
        offeringJwt: params.offeringJwt ?? null,
        promotionCode: params.promotionCode ?? null,
      })
      .toPromise()
      .then(
        (res) => {
          if (!res) {
            this.storageService.delete(STORAGE_KEY_PRESELECTED_PRODUCT);
            return {
              success: false,
              error: 'generic.error.name',
            };
          }

          const orderPreview: OrderPreview = {
            currency: res.currency,
            customerToken: res.customerToken,
            discount: res.discount
              ? {
                  startDate: parseISO(res.discount.startDate),
                  endDate: parseISO(res.discount.endDate),
                  description: res.discount.description,
                }
              : undefined,
            id: res.id,
            nextGrossTotalDate: res.nextGrossTotalDate
              ? parseISO(res!.nextGrossTotalDate)
              : undefined,
            nextTotal: res.nextTotal,
            product: {
              price: res.product.price,
              name: res.product.name,
              contractPeriod: res.product.contractPeriod,
              cancellationPeriod: res.product.cancellationPeriod,
              billingPeriod: res.product.billingPeriod,
              trialDuration: res.product.trialDuration,
            },
            total: res.total,
            trialEnabled: res.trialEnabled,
            trialEndDate: res.trialEndDate
              ? parseISO(res.trialEndDate)
              : undefined,
            scheduledChangeDate: res.scheduledChangeDate
              ? parseISO(res.scheduledChangeDate)
              : undefined,
            invoiceDate: res.invoiceDate
              ? parseISO(res.invoiceDate)
              : undefined,
            invoiceAmount: res.invoiceAmount,
            allowDebitPayment: res.allowDebitPayment,
            allowedPaymentMethods: res.allowedPaymentMethods,
            upcomingProduct: {
              price: res.upcomingProduct?.price,
              name: res.upcomingProduct?.name,
              contractPeriod: res.upcomingProduct?.contractPeriod,
              cancellationPeriod: res.upcomingProduct?.cancellationPeriod,
              billingPeriod: res.upcomingProduct?.billingPeriod,
            },
            features: res.features,
            isInitialOrder: res.isInitialOrder,
          };

          // DiscountID konnte nicht eingelöst werden
          if (params.discountId && !orderPreview.discount) {
            return {
              success: false,
              error: 'discount.not.accepted',
              value: orderPreview,
            };
          }

          return {
            success: true,
            error: undefined,
            value: orderPreview,
          };
        },
        (err: HttpErrorResponse) => {
          this.storageService.delete(STORAGE_KEY_PRESELECTED_PRODUCT);

          return {
            success: false,
            error: err.error.title,
          };
        },
      );
  }

  /**
   * Validate PaymentData
   */
  async getSelfServiceTokenOnValidPaymentData(
    payment: PaymentMethodData,
    customer: { firstname: string; lastname: string },
    address: Address,
    password?: string,
  ): Promise<MaybeValue<string, GetSelfServiceTokenError>> {
    return this.http
      .post<{ token: string }>(
        `${environment.bffUrl}/getSelfServiceTokenOnValidPaymentData`,
        {
          creditCardData:
            payment.paymentBearerType === 'CreditCard'
              ? { cardPan: payment.cardpan }
              : null,
          bankAccountData:
            payment.paymentBearerType === 'BankAccount'
              ? { iban: payment.iban, bic: payment.bic }
              : null,
          paymentBearerType: payment.paymentBearerType,
          address,
          firstName: customer.firstname,
          lastName: customer.lastname,
          password,
        },
      )
      .toPromise()
      .then(
        (res) => {
          return {
            success: true,
            value: res!.token,
          };
        },
        (err: HttpErrorResponse) => {
          return {
            success: false,
            error: err.error.title,
          };
        },
      );
  }

  /**
   * stores the current user order
   */
  storeOrderContext(
    order: OrderPreview,
    product: Product,
    offeringJwt?: string,
    promotionCode?: string,
  ) {
    this.storageService.set<OrderContext>(
      this.STORAGE_KEY_ORDER,
      {
        order,
        product,
        offeringJwt,
        promotionCode,
      },
      addDays(new Date(), 1),
    );
  }

  /**
   * update order id of stored order
   */
  updateOrderId(orderId: string) {
    const orderContext = this.retrieveOrderContext();
    if (!orderContext) {
      return;
    }
    orderContext.order.id = orderId;
    this.storeOrderContext(
      orderContext.order,
      orderContext.product,
      orderContext.offeringJwt,
      orderContext.promotionCode,
    );
  }

  /**
   * get the current order
   */
  retrieveOrderContext(): undefined | OrderContext {
    if (this.storageService.has(this.STORAGE_KEY_ORDER)) {
      const storedOrder = this.storageService.get<OrderContext>(
        this.STORAGE_KEY_ORDER,
      );

      /* istanbul ignore next */
      if (storedOrder?.product && !isNaN(storedOrder?.order?.product?.price)) {
        return storedOrder;
      }
    }

    return undefined;
  }

  /**
   * Clear Storage after successful order
   */
  clearOrder() {
    this.storageService.delete(this.STORAGE_KEY_ORDER);
  }

  /**
   * Get list of invoices for customer
   */
  async getInvoiceList(
    offset = 0,
  ): Promise<MaybeValue<Invoice[], GenericError>> {
    return this.http
      .get<Invoice[]>(`${environment.bffUrl}/invoices?offset=${offset}`)
      .toPromise()
      .then(
        (res) => {
          const invoices: Invoice[] = [];
          res!.map((invoice) => {
            invoices.push({
              id: invoice.id,
              invoiceNumber: invoice.invoiceNumber,
              currency: invoice.currency,
              totalGross: Math.abs(invoice.totalGross),
              isInvoice: invoice.isInvoice,
              created: invoice.created,
            });
          });
          return {
            success: true,
            value: invoices,
          };
        },
        (err: HttpErrorResponse) => {
          return {
            success: false,
            error: err.error.title,
          };
        },
      );
  }

  /**
   * Gets a download link for a specific invoice
   * */
  async getInvoiceDownloadUrl(
    invoiceId: string,
  ): Promise<MaybeValue<InvoiceDownloadUrl, GenericError>> {
    return this.http
      .get<InvoiceDownloadUrl>(
        `${environment.bffUrl}/invoices/${invoiceId}/downloadLink`,
      )
      .toPromise()
      .then(
        (res) => {
          const hostPrefix = environment.bffUrl.split(/\/api$/)[0];
          const invoiceDownloadUrl = {
            url: `${hostPrefix}${res!.url}`,
          } as InvoiceDownloadUrl;
          return {
            success: true,
            value: invoiceDownloadUrl,
          };
        },
        (err: HttpErrorResponse) => {
          return {
            success: false,
            error: err.error.title,
          };
        },
      );
  }

  /**
   * handles error coming from SubscriptionJS
   */
  handleError(errorData: any): Error {
    const error: Error = {
      message: '',
      name: this.translateService.instant('payment.error.unknown.headline'),
      stack: '',
    };

    if (!errorData.message) {
      errorData.message = this.translateService.instant(
        'payment.error.general.message',
      );
    }

    if (!errorData.errorMessage) {
      /* istanbul ignore else */
      if (errorData.message) {
        error.message = errorData.message;
        error.name = this.translateService.instant('payment.error.headline');
        error.stack = errorData.stack || null;
      }

      return error;
    }

    // SubscriptionJS tetherPaymentInformation
    if (errorData.details === 1083) {
      return {
        message: errorData.errorMessage,
        name: errorData.errorCode.join(' - '),
      };
    }

    try {
      const parsedError = JSON.parse(errorData.errorMessage);

      /* istanbul ignore else */
      if (parsedError) {
        error.message = parsedError.Error.Message;
        error.name = parsedError.Error.Message;
        error.stack = parsedError.Error.Details;
      }
    } catch {}

    if (typeof errorData.errorMessage !== 'string') {
      return error;
    }

    // SubscriptionJS Error
    if (errorData.errorMessage.indexOf('{') > 0) {
      error.stack = 'SubscriptionJS Error';
      error.message = this.translateService.instant(
        'payment.error.cc.' +
          errorData.errorMessage
            .substring(
              errorData.errorMessage.indexOf('{') + 1,
              errorData.errorMessage.indexOf('}'),
            )
            .toLowerCase(),
      );
    }

    // PAYOne Error Message
    if (errorData.errorMessage.indexOf('PayOne error occurred') >= 0) {
      error.stack = 'PayOne';
      error.message = errorData.errorMessage;
    }

    if (errorData.errorMessage.indexOf('PayOne bearer check failed') >= 0) {
      error.stack = errorData.errorMessage;
      if (errorData.errorCode && errorData.errorCode.includes('InvalidIban')) {
        error.message = this.translateService.instant(
          'payment.error.payone.bearer.invalid.iban',
        );
      } else {
        error.message = this.translateService.instant(
          'payment.error.payone.bearer',
        );
      }
      error.name = this.translateService.instant(
        'payment.error.bearer.headline',
      );
    }

    // SubscriptionJS IFrame Error
    if (errorData.errorMessage.indexOf('invalid card number') >= 0) {
      error.message = this.translateService.instant(
        'payment.error.invalidCardNumber',
      );
      error.name = this.translateService.instant('payment.error.cc.headline');
    }

    return error;
  }

  /**
   * Delete payment method
   */
  async deletePaymentMethod(
    password: string,
  ): Promise<
    { success: true } | { success: false; error: DeletePaymentMethodError }
  > {
    return this.http
      .post(`${environment.bffUrl}/deletePaymentMethod`, { password })
      .toPromise()
      .then(
        () => {
          return { success: true };
        },
        (err: HttpErrorResponse) => {
          return {
            success: false,
            error: err.error.title,
          };
        },
      );
  }

  /**
   * Pre-validate payment method change
   */
  async preValidatePaymentDataChange(): Promise<
    MaybeValue<PreValidatePaymentDataChangeResponse, GenericError>
  > {
    return firstValueFrom(
      this.http.get<PreValidatePaymentDataChangeResponse>(
        `${environment.bffUrl}/prevalidatePaymentDataChange`,
      ),
    ).then(
      (res) => {
        return {
          success: true,
          value: res,
        };
      },
      (err: HttpErrorResponse) => {
        return {
          success: false,
          error: err.error.title,
        };
      },
    );
  }
}
